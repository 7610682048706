import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

/**
 * Replace React.Fragment with React.StrictMode once this issue is closed
 * https://github.com/mui-org/material-ui/issues/13394
 */
ReactDOM.render(
  <React.Fragment>
    <App />
  </React.Fragment>,
  document.getElementById('root')
);
