import React, { useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import async from 'async';
import qs from 'query-string';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
}));

const parsedParams = qs.parse(window.location.search);
const pipeline = parsedParams.pipeline;
const mapPipeline = {
  'e228626b-9454-4f4f-b090-6b649f109fc1': ['Creditistampanti', 'tagday', 'Membership'],
  '6350eadb-3eca-4f7f-b90f-86f9ae62f900': [
    'Full Time Masters',
    'Masterclass',
    'Masterclasses',
    'Part Time Masters',
  ],
  '0334cb2e-3df7-4dbd-ae7f-f0a1e217fff1': ['Workshop Rooms', 'Meeting rooms'],
};

const ProductField = (props) => {
  const classes = useStyles();
  const [products, setProducts] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const onChange = props.onChange;

  const handleChange = useCallback(
    (event) => {
      const value = event.target.value;
      onChange(value);
    },
    [onChange]
  );

  useEffect(() => {
    if (props.shop) {
      let page = 1;
      let currentProductsLength = 50;
      let fetchedProducts = [];
      async.whilst(
        (cb) => {
          cb(null, currentProductsLength === 50);
        },
        (callback) => {
          const url = `https://${props.shop}/products.json?limit=50&page=${page}`;
          fetch(url)
            .then((response) => {
              return response.json();
            })
            .then((data) => {
              fetchedProducts = fetchedProducts.concat(data.products);
              page += 1;
              currentProductsLength = data.products.length;
              handleChange({ target: { value: '' } });
              callback();
            });
        },
        (err, n) => {
          fetchedProducts.sort((a, b) => {
            if (a.title < b.title) return -1;
            if (a.title > b.title) return 1;
            return 0;
          });
          setProducts(fetchedProducts);
        }
      );
    }
  }, [props.shop, handleChange]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="product-label">Product</InputLabel>
        <Select
          labelId="product"
          id="product"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={props.product}
          onChange={handleChange}
        >
          {products
            .map((elem) => {
              if (mapPipeline[pipeline] && mapPipeline[pipeline].includes(elem.product_type)) {
                return (
                  <MenuItem key={elem.id} value={elem.handle}>
                    {elem.title}
                  </MenuItem>
                );
              } else {
                return null;
              }
            })
            .filter(Boolean)}
        </Select>
      </FormControl>
    </div>
  );
};

export default ProductField;
