import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
}));

const languages = {
  'shop.singularityu.it': {
    languages: [
      {
        id: 'it',
        value: 'Italian',
        default: true,
      },
      {
        id: 'en',
        value: 'English',
      },
    ],
  },
  'it.talentgarden.shop': {
    languages: [
      {
        id: 'it',
        value: 'Italian',
        default: true,
      },
      {
        id: 'en',
        value: 'English',
      },
    ],
  },
  'at.talentgarden.shop': {
    languages: [
      {
        id: 'en',
        value: 'English',
        default: true,
      },
    ],
  },
  'ie.talentgarden.shop': {
    languages: [
      {
        id: 'en',
        value: 'English',
        default: true,
      },
    ],
  },
  'es.talentgarden.shop': {
    languages: [
      {
        id: 'es',
        value: 'Spanish',
        default: true,
      },
      {
        id: 'en',
        value: 'English',
      },
    ],
  },
};

const LanguageField = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const onChange = props.onChange;

  useEffect(() => {
    const defaultValue = props.shop
      ? languages[props.shop].languages.filter((elem) => elem.default === true)?.[0].id
      : '';
    const defaultObj = {
      id: defaultValue,
      default: true,
    };
    onChange(defaultObj);
  }, [onChange, props.shop]);

  const handleChange = (event) => {
    const value = event.target.value;
    const isDefault =
      languages[props.shop].languages.filter((elem) => elem.id === value)[0].default || false;
    const currentSelection = {
      id: value,
      default: isDefault,
    };
    props.onChange(currentSelection);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="language-label"> Language </InputLabel>
        <Select
          labelId="language"
          id="language"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={props.language.id || ''}
          onChange={handleChange}
        >
          {languages[props.shop] &&
            languages[props.shop].languages.map((elem) => {
              return (
                <MenuItem key={elem.id} value={elem.id}>
                  {elem?.value}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </div>
  );
};

export default LanguageField;
