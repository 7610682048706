import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
}));

const quantityPerShops = {
  'shop.singularityu.it': false,
  'it.talentgarden.shop': true,
  'at.talentgarden.shop': true,
  'es.talentgarden.shop': true,
  'ie.talentgarden.shop': true,
};

const DiscountField = (props) => {
  const classes = useStyles();

  const handleChange = (event) => {
    const value = event.target.value;
    props.onChange(value);
  };
  const isVisible = quantityPerShops[props.shop];

  const Field = (
    <TextField
      className={classes.formControl}
      id="discount"
      label="Discount code"
      type="text"
      value={props.discount}
      onChange={handleChange}
    />
  );
  return isVisible ? Field : '';
};

export default DiscountField;
