import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import qs from 'query-string';
import Copy from './Components/Copy';
import Open from './Components/Open';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '80%',
  },
}));

const LinkField = (props) => {
  const classes = useStyles();
  const [dealId, setDealId] = useState('');
  const [link, setLink] = useState('');

  const parsedParams = qs.parse(window.location.search);

  useEffect(() => {
    if (parsedParams.hs_object_id) setDealId(parsedParams.hs_object_id);
    if (props.shop && props.product) {
      const isNotDefaultLang =
        props.language.default === undefined || props.language.default === false;
      const language = isNotDefaultLang ? `${props.language.id}/` : '';
      let link = `https://${props.shop}/${language}products/${props.product}`;
      if (dealId) link += `?deal_id=${dealId}`;
      if (!isNaN(parseInt(props.quantity))) {
        const specialChar = link.includes('?') ? '&' : '?';
        link += `${specialChar}q=${props.quantity}`;
      }
      if (props.discount) {
        const specialChar = link.includes('?') ? '&' : '?';
        link += `${specialChar}discount=${props.discount}`;
      }
      setLink(link);
    } else {
      setLink('');
    }
  }, [
    props.shop,
    props.product,
    props.language,
    props.quantity,
    props.discount,
    parsedParams.hs_object_id,
    dealId,
  ]);

  return (
    <Box>
      <TextField
        className={classes.formControl}
        id="link"
        label="Shopify link"
        min={0}
        value={link}
        InputProps={{
          readOnly: true,
        }}
        variant="outlined"
      />
      <Copy link={link} />
      <Open link={link} />
    </Box>
  );
};

export default LinkField;
