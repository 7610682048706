import React from 'react';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Tooltip from '@material-ui/core/Tooltip';

const Open = (props) => {
  const Action = (
    <Tooltip title="Open">
      <OpenInNewIcon
        style={{ cursor: 'pointer', margin: '15px', color: '#ee7660' }}
        fontSize={'large'}
        onClick={() => {
          window.open(props.link);
        }}
      ></OpenInNewIcon>
    </Tooltip>
  );
  return props.link ? Action : null;
};

export default Open;
