import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
}));

const shops = [
  {
    id: 'at.talentgarden.shop',
    value: 'Talent Garden Austria',
  },
  {
    id: 'it.talentgarden.shop',
    value: 'Talent Garden Italy',
  },
  {
    id: 'es.talentgarden.shop',
    value: 'Talent Garden Spain',
  },
  {
    id: 'ie.talentgarden.shop',
    value: 'Talent Garden Ireland',
  },
];

const ShopField = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    const value = event.target.value;
    props.onChange(value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="shop-label"> Shop </InputLabel>{' '}
        <Select
          labelId="shop-label"
          id="shop"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={props.shop}
          onChange={handleChange}
        >
          {' '}
          {shops.map((elem) => (
            <MenuItem key={elem.id} value={elem.id}>
              {' '}
              {elem.value}{' '}
            </MenuItem>
          ))}{' '}
        </Select>{' '}
      </FormControl>{' '}
    </div>
  );
};

export default ShopField;
