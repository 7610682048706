import React from 'react';
import Container from '@material-ui/core/Container';
import Form from './Form/Form';
import { ThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import './App.css';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ee7660',
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xl">
        <Form />
      </Container>
    </ThemeProvider>
  );
};

export default App;
