import React, { useState } from 'react';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Tooltip from '@material-ui/core/Tooltip';

const Copy = (props) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const Action = (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title="Copied!"
      >
        <CopyToClipboard text={props.link}>
          <FileCopyIcon
            style={{ cursor: 'pointer', margin: '15px', color: '#ee7660' }}
            fontSize={'large'}
            onClick={() => {
              setOpen(true);
              setTimeout(() => {
                setOpen(false);
              }, 2000);
            }}
          ></FileCopyIcon>
        </CopyToClipboard>
      </Tooltip>
    </ClickAwayListener>
  );
  return props.link ? Action : null;
};

export default Copy;
