import React from 'react';
import {
  makeStyles
} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
}));

const quantityPerShops = {
  'shop.singularityu.it': true,
  'it.talentgarden.shop': false,
  'at.talentgarden.shop': false,
};

const QuantityField = (props) => {
  const classes = useStyles();

  const handleChange = (event) => {
    const value = event.target.value;
    props.onChange(value);
  };
  const isVisible = quantityPerShops[props.shop];
  const Field = ( <
    TextField className = {
      classes.formControl
    }
    id = "quantity"
    label = "Quantity"
    type = "number"
    value = {
      props.quantity
    }
    onChange = {
      handleChange
    }
    InputProps = {
      {
        inputProps: {
          min: 1,
          max: 100
        }
      }
    }
    />
  );
  return isVisible ? Field : '';
};

export default QuantityField;
