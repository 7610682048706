import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import ShopField from './Fields/ShopField';
import ProductField from './Fields/ProductField';
import LanguageField from './Fields/LanguageField';
import LinkField from './Fields/LinkField';
import QuantityField from './Fields/QuantityField';
import DiscountField from './Fields/DiscountField';

const Form = () => {
  const [shop, setShop] = useState('');
  const [product, setProduct] = useState('');
  const [language, setLanguage] = useState({ id: '', default: false });
  const [quantity, setQuantity] = useState('');
  const [discount, setDiscount] = useState('');

  useEffect(() => {
    setProduct('');
    setQuantity('');
    setDiscount('');
  }, [shop]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ShopField shop={shop} onChange={setShop}></ShopField>
      </Grid>

      <Grid item xs={12}>
        <ProductField product={product} onChange={setProduct} shop={shop}></ProductField>
      </Grid>

      <Grid item xs={4}>
        <LanguageField language={language} onChange={setLanguage} shop={shop}></LanguageField>
      </Grid>
      <Grid item xs={4}>
        <DiscountField onChange={setDiscount} discount={discount} shop={shop}></DiscountField>
        <QuantityField quantity={quantity} onChange={setQuantity} shop={shop}></QuantityField>
      </Grid>
      <Grid item xs={4}></Grid>

      <Grid item xs={12}>
        <LinkField
          shop={shop}
          product={product}
          language={language}
          quantity={quantity}
          discount={discount}
        ></LinkField>
      </Grid>
    </Grid>
  );
};

export default Form;
